.star-rating-filter-component-container {
  & .filter-options-container {
    & > .filter-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 24px;
      margin-top: 8px;
      font-size: 16px;

      &:hover {
        cursor: pointer;
      }

      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
      }

      & > .checkbox-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        border-radius: 4px;
      }

      & > .active {
        & > .checkbox {
          background-color: white;
          width: 17px;
          height: 17px;
          mask-size: contain;
        }

        &:hover {
          cursor: pointer;
        }

        &:focus,
        &:active {
          border: 1px solid white;
        }
      }

      & > .inactive {
        border: 1px solid lightgray;

        & > .checkbox {
          background-color: transparent;
        }
      }

      & > .star-rating-container {
        height: 100%;
        margin-right: 8px;
      }

      & > .text-container {
        height: 100%;
      }

      & > .counter-container {
        margin-left: auto;
        height: 100%;
      }
    }
  }
}
