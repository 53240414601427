@import "src/assets/scss/common/breakpoints.scss";
.voucher-code-component {
  & p {
    margin-bottom: 12px;
  }
  & .input-component {
    position: relative;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    font-size: 16px;
    color: #7C7F7D;
    background-color: #FFFFFF;
    border: 1px solid #7DC4E9;
    border-radius: 4px;

    @include responsive(XXS) {
      width: 100%;
    }
    @include responsive(SM) {
      width: 390px;
    }

    & input {
      border: none;
      outline: none;
      flex-grow: 1;
      font-size: 16px;
    }

    & input:disabled {
      background-color: #FFFFFF;
      pointer-events: none;
    }

    &:hover {
      border: 1px solid #7C7F7D;
      cursor: pointer;
    }

    &:focus {
      outline: none !important;
    }
  }

  & .error-voucher {
    border: 2px solid #E00034;
  }

  & .success-voucher {
    border-color: #B5B6B5;
    &:hover {
      border: 1px solid #B5B6B5;
      & input {
        border: none;
      }
    }
  }

  & .voucher-button-container {
    & .voucher-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      height: 40px;
      padding: 0 20px;
      font-size: 16px;
      color: #FFFFFF;
      background-color: #003F72;
      border-radius: 4px;
      border: 2px solid transparent;
  
      &:hover {
        background-color: #0158A0;
        cursor: pointer;
      }
  
      &:disabled {
        color: #7C7F7D;
        background-color: #B5B6B5;
      }
  
      @include responsive(XXS) {
        margin-top: 8px;
      }
  
      @include responsive(SM) {
        margin-top: 16px;
      }
    }
  
    & .loading {
      color: #7C7F7D;
      background-color: #B5B6B5;
      pointer-events: none;

      & .button__loading-spinner {
        color: #7C7F7D;
        margin-right: 12px;
      }
    }
  }

  & .voucher-loading {
    border: 1px solid #B5B6B5 !important;
  }

  & .voucher-code-result {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
  }

  & .voucher-code-result {
    @include responsive(XXS) {
      width: 100%;
      font-size: 14px;
    }
    @include responsive(SM) {
      width: 390px;
      font-size: 16px;
    }
    & .voucher-code-success {
      line-height: 40px;
      height: 40px;
      display: flex;
      justify-content: space-between;

      @include responsive(XXS) {
        margin-bottom: 8px;
      }
      @include responsive(SM) {
        margin-bottom: 0px;
      }

      & .voucher-code-description {
        display: flex;
        margin: auto 0;

        & .success-icon {
          & svg {
            margin: 6px 10px auto 10px;
          }
          & svg path {
            width: 18px;
            height: 16px;
          }
        }
        & .success-description {
          line-height: 40px;
        }
      }

      & .voucher-code-remove {
        line-height: 16px;
        margin: auto 0;
        & svg {
          height: 18px;
          margin: 0 5px;
        }
        & svg path {
          width: 10px;
          height: 10px;
        }
      }
    }
    & .voucher-code-error {
      line-height: 40px;
      height: 40px;
      display: flex;
      justify-content: space-between;

      @include responsive(XXS) {
        margin-bottom: 8px;
      }
      @include responsive(SM) {
        margin-bottom: 0px;
      }

      & .voucher-code-description {
        display: flex;
        margin: auto 0;

        & .error-icon {
          line-height: 16px;
          & svg {
            margin: 0 10px;
          }
          & svg path {
            height: 20px;
            width: 20px;
          }
        }
        & .error-description {
          line-height: 28px;
        }
      }

      & .voucher-code-remove {
        line-height: 16px;
        margin: auto 0;
        vertical-align: baseline;
        & svg {
          margin: 0 5px;
          height: 18px;
        }
        & svg path {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}
