@import "src/assets/scss/common/breakpoints.scss";

.destination-filter-component-container {
    box-sizing: border-box;

    &>.accordion-content>.destination-filter-component-content {
        position: relative;

        &>.filter-options {

            &>.header {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 28px;
                font-size: 16px;
                font-weight: bolder;
                letter-spacing: 0.46px;
                color: #002230;
            }

            &>.selector {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 48px;
                font-size: 16px;
                letter-spacing: 0.46px;
                border: 1px solid #00A1DE;
                border-radius: 4px;

                &:hover {
                    cursor: pointer;
                    border: 2px solid lightgray;
                }

                &>.icon-location {
                    width: 10px;
                    margin-left: 16px;
                    background-color: #00A1DE;
                    -webkit-mask: url('../../../assets/icons/klm/location-marker-S.svg') no-repeat center;
                    mask-size: contain;
                }

                &>.text {
                    margin-left: 12px;
                    padding-right: 10px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    &>.active {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        color: #002230;
                    }

                    &>.inactive {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        color: #7C7F7D;
                    }
                }

                &>.icon-arrow {
                    width: 10px;
                    height: 5px;
                    margin-left: auto;
                    margin-right: 16px;

                    &>.icon-drop-down {
                        background-color: #00A1DE;
                        width: 100%;
                        height: 100%;
                        -webkit-mask: url("../../../assets/icons/klm/ic_arrow_drop_down.svg") no-repeat center;
                        mask-size: auto;
                    }

                    &>.icon-drop-up {
                        background-color: #00A1DE;
                        width: 100%;
                        height: 100%;
                        -webkit-mask: url("../../../assets/icons/klm/ic_arrow_drop_up.svg") no-repeat center;
                        mask-size: auto;
                    }
                }
            }

            &>.active {
                border: 2px solid #00A1DE;
            }

            &>.options-container {
                position: relative;

                &>.options-content-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    z-index: 5;
                    background-color: #FFFFFF;
                    box-shadow: 0 10px 20px 0 #7C7F7D;

                    &>.options-header {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        height: 40px;
                        padding: 6px 12px 0 12px;
                        font-size: 16px;
                        font-weight: bolder;
                        letter-spacing: 0.46px;
                        color: #000D12;
                    }

                    &>.options-error {
                        display: flex;
                        flex-direction: row;
                        margin: 10px 0;
                        line-height: 24px;

                        &>.icon {
                            width: 40px;
                            height: 40px;
                            margin-top: -8px;
                            margin-left: 12px;
                            background-color: #00A1DE;
                            -webkit-mask: url('../../../assets/icons/klm/ic_error_outline_klm.svg') no-repeat center;
                            mask-size: contain;
                        }

                        &>.text {
                            padding: 0 12px 0 8px;
                            font-size: 16px;
                            letter-spacing: 0.46px;
                            color: #003145;
                        }
                    }

                    &>.options-content {
                        max-height: 250px;
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            width: 20px;
                        }

                        &::-webkit-scrollbar-track {
                            background-color: #FFFFFF;
                            border: 10px solid #FFFFFF;
                            border-radius: 0px;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: #8F8F8F;
                            border: 7px solid #FFFFFF;
                        }

                        &>.option {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            height: 34px;
                            padding: 0 12px;
                           
                           &.disable-option-cursor {
                                cursor: pointer; 
                            }

                            &:hover {
                                background-color: #EDEEEE;
                                cursor: pointer;
                            }
                       
                            &>div {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                            }

                            &>.checkbox-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 16px;
                                height: 16px;
                                margin-right: 8px;
                                border: 1px solid #7C7F7D;
                                border-radius: 4px;
                            }

                            &>.active {
                                border: 1px solid #00A1DE;
                                background-color: #00A1DE;

                                &>.checkbox {
                                    background-color: white;
                                    width: 12px;
                                    height: 12px;
                                    -webkit-mask: url("../../../assets/icons/klm/ic_check.svg") no-repeat center;
                                    mask-size: contain;
                                }

                                &:hover {
                                    cursor: pointer;
                                    border: 1px solid #3BC9FF;
                                    background-color: #3BC9FF;
                                }

                                &:focus,
                                &:active {
                                    border: 1px solid white;
                                    background-color: #00A1DE;
                                    outline: 1px solid #3BC9FF;
                                }
                            }

                            &>.inactive {
                                border: 1px solid #7C7F7D;

                                &>.checkbox {
                                    background-color: transparent;
                                }

                                &:hover {
                                    cursor: pointer;
                                    border: 1px solid #C2DEEA;
                                }

                                &:focus,
                                &:active {
                                    border: 1px solid #00A1DE;
                                }
                            }

                            &>.flag-container {
                                height: 16px;
                                margin-right: 8px;
                                &>.flag-small {
                                    &.disable-flag {
                                        opacity: 0.5;
                                    }
                                }
                            }

                            &>.text-container {
                                height: 100%;
                                &.disable-text {
                                    opacity: 0.5;
                                }
                            }
                        }
                    }

                    &>.options-button-container {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        height: 72px;
                        padding: 0 16px;
                        background-color: #FFFFFF;
                        box-shadow: 0 10px 20px 0 #7C7F7D;
                        .cancel-button {
                            @include responsive(XXS) {
                                display: none;
                            }
                            @include responsive(SM) {
                                display: block;
                            }                       
                         }

                        &>div {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            height: 40px;
                            padding: 0 16px;
                        }

                        &>.disabled {
                            color: #222222;
                        }
                    }
                }
            }
        }

        &>.filter-options:nth-child(1) {
            margin-top: 2px;
        }

        &>.filter-options:nth-child(2) {
            margin-top: 7px;
        }
    }

    & .ml-2 {
        margin-left: 8px;
    }
}

@media only screen and (min-width: 1px) and (max-width: 576px) {
    .destination-filter-component-container>
    .accordion-content>
    .destination-filter-component-content>
    .filter-options>
    .options-container>
    .options-content-container {
        position: fixed;
        top: 0;
        left: 0;
        height: calc(100vh - 72px);
        
        &:has(.options-error) {

            &>.options-content {
                max-height: calc(100vh - 180px);
            }
        }

        &>.options-content {
            max-height: calc(100vh - 112px);
        }

        &>.options-button-container {
            position: fixed;
            bottom: 0;
        }
    }
}

.search-input {
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
    margin: 0;
    cursor: pointer;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
    
    &:focus-visible {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

.selected-options {
    padding: 8px 16px;
    margin: 0 8px 8px 0;
    
    &.desktop-only {
      display: none;
      
      @include responsive(MD){
        display: flex; 
        flex-wrap: wrap; 
        gap: 4px;
        margin: 0;
      }
    }
    
    .selected-option {
      display: flex;
      align-items: center;
      padding-right: 6px;
      align-items: center;
      cursor: pointer;
  
      .checkbox-container {
        margin-right: 8px;
      }
  
      .flag-container {
        display: flex;
        margin-right: 8px;
        
        .flag-small {
          width: 16px;
          height: 12px;
          border-radius: 2px;
        }
      }
    }
  }