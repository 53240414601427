@import "src/assets/scss/common/breakpoints.scss";

.regular-datepicker {
    border-color: transparent;
    padding: 16px;
    border-radius: 0;
    width: 100%;
  
    @media (max-width: 576px) {
      top: 48px;
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh - 48px);
    }
  
    @include responsive(SM) {
      width: 356px;
      height: 880px;
    }
  
    @include responsive(MD) {
      top:6px;
    }
  
    @include responsive(LG) {
      display: flex;
      top: 8px;
      width: 656px;
      height: 524px;
      position: relative;
    }
}

.regular-shift-picker {
    @media (max-width: 576px) {
      margin-top: 100px;
      top: 0;
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh - 150px);
    }
    @include responsive (SM) {
      margin-top: 70px;
    }
    @include responsive (LG) {
      margin-top:72px;
    }
  }

  .calendar-disabled {
    @media (max-width: 576px) {
      top: 0;
    }
  }