@import "src/assets/scss/common/breakpoints.scss";
@import "src/assets/scss/klm/klm-colors.scss";

@media (max-width: 576px) {
  .react-datepicker-popper[data-placement^=bottom] {
    padding-top: 0px;
  }
}

.react-datepicker-popper {
  z-index: 6;
  display: flex;
  background-color: none;
  @media (max-width: 576px) {
    position: fixed !important;
    height: calc(100vh - 48px);
    transform: none !important;
    top: -10px;
    left: 0;
  }

  @include responsive(SM) {
    position: absolute;
    height: fit-content;
  }
}

.react-datepicker {
  border-color: transparent;
  padding: 16px;
  border-radius: 0;
  width: 100%;

  @media (max-width: 576px) {
    top: 48px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 48px);
  }

  @include responsive(SM) {
    width: 356px;
    height: 880px;
  }

  @include responsive(MD) {
    top: 6px;
  }

  @include responsive(LG) {
    display: flex;
    top: 8px;
    width: 656px;
    height: 524px;
    position: relative;
  }
  .react-datepicker__header {
    border: none;

    &:after {
      content: "";
      position: absolute;
      width: 274px;
      padding-top: 8px;
      transform: translateX(-50%);
    }

    @media (max-width: 576px) {
      margin: 0 auto;
    }

    .react-datepicker__current-month {
      height: 24px;
      color: #003145;
      font-size: 16px;
      letter-spacing: 0.46px;
      line-height: 24px;
      margin-bottom: 12px;
    }
  }

  .react-datepicker__navigation {
    display: flex;

    .react-datepicker__navigation-icon {
      &:before {
        height: 12px;
        width: 12px;
      }
    }
  }

  .react-datepicker__day-names {
    .react-datepicker__day-name {
      position: relative;
      left: -4px;
      box-sizing: border-box;
      width: 40px;
      margin: 0;

      @media (max-width: 992px) {
        left: 0;
      }
    }
  }

  & .react-datepicker__day,
  & .react-datepicker__month-text,
  & .react-datepicker__quarter-text,
  & .react-datepicker__year-text {
    &--selected,
    &--in-selecting-range,
    &--in-range {
      &:first-child {
        &:hover {
          color: white;
          width: 32px;
          border-radius: 50%;
        }
      }
    }
  }
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__month-container {
    width: 100%;
    min-height: 306px;

    .react-datepicker__header {
      display: inline-block;
      width: 100%;

      .react-datepicker__current-month {
        font-size: 16px;
        display: inline-block;
        width: 196px;
      }
    }
  }
}

.react-datepicker__input-container {
  box-sizing: border-box;
  width: 100%;

  & input {
    caret-color: transparent;
    cursor: pointer;
    position: relative;
    font-size: 16px;
    line-height: 40px;
    justify-content: center;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border: none;
    width: 100%;

    &:hover {
      font-weight: bold;
    }

    &:focus {
      outline: none;
    }
  }
}

.datepicker-label {
  box-sizing: border-box;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
}

.datepicker-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 2px);
  height: 72px;
  padding: 8px;
  left: 0;
  z-index: 10;

  @media (max-width: 576px) {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  @include responsive(SM) {
    position: absolute;
    top: 104px;
  }

  @include responsive(LG) {
    padding: 0;
    top: 124px;
    right: 0;
    flex-direction: row;
    justify-self: right;
    align-items: center;
    box-sizing: border-box;
    width: 160px;
  }

  & > .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 18px;
    font-size: 16px;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.datepicker-component {
  & label {
    box-sizing: border-box;
    display: flex;
    border-radius: 4px;
    cursor: pointer;
    background-color: white;

    @include responsive(XXS) {
      width: 100%;
    }

    & .datepicker-active {
      font-weight: bold;
    }
  }
}

.date-range-modifiers {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  top: 10px;
  padding-bottom: 10px;
  margin: 0 auto 0;

  @media (max-width: 576px) {
    padding-bottom: 36px;
  }

  @media (max-width: 993px) {
    margin: 24px auto;
    display: flex;
    width: calc(100% - 32px);
  }

  & button {
    margin: 8px 16px 0 0;
    display: block;
  }

  & .date-modifier-button {
    white-space: nowrap;
    box-sizing: border-box;
    border-radius: 22px;
    height: 40px;
    font-size: 16px;
    letter-spacing: 0.46px;
    line-height: 24px;
    text-align: center;
    padding: 10px 15px;
  }

  & .flexibility-button-selected {
    box-sizing: border-box;
    border-radius: 22px;
  }
}

.custom-datepicker-input {
  display: flex;
  font-family: UniversalSans-Text-Light;

  & .travel-flexibility-custom-input {
    position: relative;
    margin-top: 10px;
    display: inline-flex;
    width: 20%;
    margin-right: 20px;
    padding-right: 0;
    @media (min-width: 576px) and (max-width: 1200px) {
      margin-right: 10px;
      margin-top: 10px;
    }
  }
}

.custom-input {
  border: none;
  background-color: white;
  padding: 0;
  width: 100%;
  display: flex;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  justify-content: space-between;

  & .dates-placeholder {
    display: inline-flex;
    width: 100%;

    & .dates-placeholder-wrapper {
      height: 100%;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
    }
  }

  & .travel-flexibility-placeholder {
    font-weight: bold;
    width: 25%;
    display: flex;
    justify-content: right;
    margin-right: 6px;

    @media (min-width: 576px) and (max-width: 769px) {
      min-width: 25%;
    }
  }

  & .dropdown-caret-placeholder {
    position: relative;
    top: 17px;
  }

  &:hover {
    font-weight: bold;
  }
}

.price-datepicker {
  .react-datepicker__day-names {
    .react-datepicker__day-name {
      position: relative;
      left: 0px;
      box-sizing: border-box;
      width: 42px;
      margin: 0;
    }
  }
  .react-datepicker__month-container {
    .react-datepicker__month {
      .react-datepicker__day {
        width: 40px;
        margin: 0 1px;
      }

      .react-datepicker__day--outside-month {
        pointer-events: none;
      }

      .react-datepicker__week {
        height: 48px;
        margin: 4px;

        .react-datepicker__day {
          margin: 0 1px;
        }

        .react-datepicker__day--selecting-range-end {
          position: relative;
          color: white;
          width: 40px;
          margin: 0 2px;
        }

        & .react-datepicker__day--range-end,
        .react-datepicker__day--range-start {
          position: relative;
          width: 40px;
        }

        & .react-datepicker__day--outside-month {
          opacity: 0;
        }
      }
    }
  }

  &
    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    position: relative;
  }

  & .react-datepicker__day--selected {
    position: relative;
    width: 24px;
  }

  & .react-datepicker__day--in-selecting-range {
    border-radius: 0;
    width: 40px;
  }

  & .react-datepicker__day--selected {
    position: relative;
    color: white;
    width: 40px;
    padding: 0;
  }

  & .react-datepicker__day--keyboard-selected:not(:first-child) {
    background-color: transparent;
  }
  .day-cell {
    height: 46px;
    align-items: center;
    justify-content: center;
    position: relative;
    text-decoration: none;
    border-bottom: none;

    span {
      position: relative;
      top: 2px;
    }
    & .price,
    & .offer-price {
      font-size: 10px;
      position: relative;
      top: -8px;
    }
  }
}
