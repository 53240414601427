@import "./src/assets/scss/common/breakpoints.scss";

.traveller-rooms-component-wrapper {

  & .traveller-rooms-component {
    position: absolute;
    top: 0;
    right: -14px;
    width: 408px;
    padding: 24px;
    box-sizing: border-box;
    z-index: 3;
    cursor: auto;

    @include responsive(SM) {
      top: 50px;
    }

    & > div {
      box-sizing: border-box;
      border-radius: 2px;
    }

    // ***** ROOM INFO *****
    & .room-info {
      width: 360px;
      display: flex;
      flex-direction: row;
      margin-bottom: 24px;

      & > div:nth-child(1) {
        width: 32px;
      }

      & > div:nth-child(2) {
        width: 328px;
      }

      & .info-icon {
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
      }
    }

    // ***** ROOM SELECTOR *****
    & .room-selector {
      width: 360px;
      height: 88px;
      padding: 24px;
      margin-bottom: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;

      & .selector-text-container {
        & > div:nth-child(1) {
          font-family: "UniversalSans-Text-Regular";
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0;
        }

        & > div:nth-child(2) {
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0;
        }
      }
    }

    & .selector-numeric-stepper {
      height: 40px;
      width: 136px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;

      & > div {
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
      }

      & .value-indicator {
        font-size: 16px;
        border-radius: 4px;
        box-sizing: border-box;
      }

      & .minus-button {
        & .icon {
          width: 13px;
          height: 2px;
          mask-size: auto;
        }
      }

      & .plus-button {
        & .icon {
          width: 13px;
          height: 13px;
          mask-size: contain;
        }
      }

      & .enabled {
        &:hover {
          cursor: pointer;
        }
      }

      & .disabled {
        &:hover,
        &:focus,
        &:active {
          cursor: default;
        }
      }
    }
    & .traveller-selector-container {
      width: 360px;
      padding: 24px;
      margin-bottom: 24px;

      & .traveller-selector-component {
        & .header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          box-sizing: border-box;

          & > div:nth-child(2) {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.23px;
            display: flex;
            align-items: flex-end;
            vertical-align: bottom;

            &:hover {
              cursor: pointer;
            }
          }
        }

        & .item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-top: 24px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .traveller-rooms-component-wrapper {

    & .button-container {
      position: fixed;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      bottom: 0;
      left: 0;
      height: 72px;
      padding-left: 8px;
      padding-right: 8px;
      width: 100%;
      z-index: 10;
    }


    & .traveller-rooms-component {
      position: fixed;
      width: 100%;
      left: 0;
      height: calc(100% - 64px);
      padding-left: 8px;
      overflow: auto;
      justify-content: center;
      box-shadow: none;

      & .room-selector,
      & .traveller-selector-container,
      & .room-info {
        width: 100%;
        max-width: 360px;
        margin-left: auto;
        margin-right: auto;
      }

      & .button-container-desktop {
        display: none;
      }
    }
  }
}

@include responsive(SM) {
  .traveller-rooms-component-wrapper {

    & .button-container {
      display: none;
    }

    & .traveller-rooms-component {
      & .button-container-desktop {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

      }
    }
  }
}
