.tripadvisor-rating-filter-component-container {

    & .filter-options-container {

        & .tripadvisor-disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &>.filter-option {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 24px;
            margin-top: 8px;
            font-size: 16px;

            &:hover {
                cursor: pointer;
            }

            &>div {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;
            }

            &>.checkbox-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                margin-right: 8px;
                border: 1px solid lightblue;
                border-radius: 50%;

                &>.checkbox {
                    width: 16px;
                    height: 16px;
                    background-color: lightblue;
                    border-radius: 50%;
                }
            }

            & > .active {
                border: 1px solid lightblue;

                &>.checkbox {
                    background-color: lightblue;
                }
            }

            & > .inactive {
                border: 1px solid lightgray;

                &>.checkbox {
                    background-color: transparent;
                }
            }

            &>.tripadvisor-rating-container {
                display: flex;
                flex-direction: row;
                height: 100%;
                margin-right: 8px;
            }

            &>.text-container {
                height: 100%;
            }

            &>.counter-container {
                margin-left: auto;
                height: 100%;
            }
        }
    }
}