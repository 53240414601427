@import "../../../assets/scss/common/breakpoints.scss";

.side-filter-bar-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 10;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  & * {
    box-sizing: border-box;
  }

  @include responsive(XXS) {
    width: 100%;
  }

  @include responsive(SM) {
    width: 375px;
  }

  .filters-component-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  .filters-component {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding-bottom: 72px; 
  }

  .filters-component-elements {
    flex: 1;
    position: relative;
  }

  .filters-header {
    padding: 16px;
    font-weight: bold;
  }

  .filters-content {
    padding: 0 16px;
  }

  .filters-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 72px;
    padding: 0 16px;
    background-color: white;

    &>.button:nth-child(1) {
      height: 40px;
    }

    &>.button:nth-child(2) {
      height: 40px;
    }

    &.hidden {
      display: none;
    }

    &.visible {
      display: flex;
    }

    @include responsive(XXS) {
      position: fixed; 
      bottom: 0;
      width: 100%;
      z-index: 11;
    }

    @include responsive(SM) {
      width: 375px;
    }

    @include responsive(XL) {
      position: static; 
      width: inherit;
      z-index: inherit;
    }
  }
}

#overlay-root {
  background-color: white;
  z-index: 10;
  width: 100%;
}