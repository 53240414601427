@import "src/common/colours";
@import "src/assets/scss/klm/klm-buttons.scss";



.car-component {
  & .option-no-available {
    margin: 0 0 12px 0;
  }
  & .expand-more-button-wrapper {
    display: flex;
    flex: 1;
    & .expand-more-cars {
      margin: 12px auto;
      & .button-container {
        display: flex;

        & .dropdown-caret {
          margin-right: 10px;
          margin-left: 10px;
          width: 10px;

          &.rotate {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  &>.car-component__opacity {
    opacity: 0.5;
    pointer-events: none;
  }
}