@import "src/assets/scss/klm/klm-colors.scss";
@import "src/assets/scss/transavia/transavia-colors.scss";
@import "src/assets/scss/klm/klm-fonts.scss";
@import "src/assets/scss/common/breakpoints.scss";

.datepicker-header-container {
  display: flex;
  flex-wrap: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: white;
  z-index: 10;

  @include responsive(XXS) {
    width: 100%;
  }
  @include responsive(SM) {
    width: calc(100% - 2px);
    left: -5px;
  }

  @include responsive(LG) {
    width: 100%;
    left: 0px;
  }

  & .datepicker-header-buttons {
    width: 264px;
    display: flex;
    justify-content: space-evenly;

    & .price-datepicker-button,
    & .regular-datepicker-button {
      width: 120px;
      border: none;
    }
  }

  @media (max-width: 576px) {
    position: fixed;
    top: 0;
    left: 0;
  }
}

.datepicker-header-input {
  position: absolute;
  top: 102px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  height: 64px;
  padding-left: 24px;
  background-color: white;
  z-index: 20;
  @media (max-width: 576px) {
    position: fixed;
    top: 96px;
  }

  @include responsive(XXS) {
    min-width: 200px;
    width: 100%;
    left: 0;
  }

  @include responsive(SM) {
    width: 100%;
    left: -5px;
    width: 356px;
  }

  @include responsive(LG) {
    width: 100%;
    left: 0;
  }

  & .datpicker-input-duration {
    position: absolute;

    @include responsive(XS) {
      top: -18px;
    }

    @include responsive(MD) {
      top: -10px;
    }
    @include responsive(LG) {
      top: -10px;
    }
  }
}

.datepicker-header-duration {
  width: 100%;
  position: absolute;
  top: 164px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  height: 24px;
  line-height: 24px;
  background-color: white;
  padding-left: 24px;


  @media (max-width: 576px) {
    position: fixed;
    top: 160px;
  }

  @include responsive(XXS) {
    width: 100%;
    left: 0;
  }

  @include responsive(SM) {
    width: 100%;
    left: -5px;
    width: 356px;
  }

  @include responsive(LG) {
    width: 100%;
    left: 0;
  }
}