@import "src/assets/scss/common/breakpoints.scss";

.price-datepicker {
  & .react-datepicker__month-container {
    height: 400px;
  }
  & .react-datepicker__children-container {
    display: block;
    width: calc(100% - 48px);

    @media (min-width: 576px) and (max-width: 993px) {
      position: absolute;
      top: 614px;
      background-color: transparent;
      width: 356px;
      left: -6px;
      height: 212px;
    }

    @include responsive(LG) {
      position: absolute;
      top: 320px;
    }

    & .button-container {
      width: 100%;
    }

    & .MuiPaper-root {
      padding: 0;
      border-radius: 4px;
      height: 20px;
      box-sizing: border-box;
      align-items: center;
      @include responsive(XXS) {
        display: flex;
        justify-content: center;
        height: 48px;
      }

      @include responsive(SM) {
        height: 20px;
      }

      @include responsive(LG) {
        display: inline-flex;
      }
    }
    & .price-datepicker-header {
      background-color: transparent;
      position: absolute;
      
      @include responsive(XXS) {
        top: 0;
        width: 100%;
      }

      @include responsive(SM) {
        box-sizing: content-box;
        width: calc(100% + 2px);
        top: -808px;
      }

      @include responsive(LG) {
        width: calc(100% + 50px);
        top: -500px;
        left: -23px;
      }
    }

    & .price-calendar-disclaimer {
      @include responsive(XXS) {
        margin-top: 28px;
      }
      @include responsive(LG) {
        margin-top: 8px;
      }
    }

    & .circle {
      height: 12px;
      width: 12px;
      margin: 0 8px 0 0;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      top: 2px;
    }

    & .price-calendar-disclaimer {
      background-color: white !important;
      & .MuiAlert-icon {
        display: none;
      }
    }
    & .children-content {
      font-size: 14px;
      @media (min-width: 576px) and (max-width: 993px) {
        position: absolute;
        width: calc(100% - 8px);
        top: 200px;
        padding: 0 20px;
      }
      @include responsive(LG) {
        position: absolute;
        top: 82px;
        left: 0;
      }
    }
    & .price-datepicker-button-container {
      @media (min-width: 576px) and (max-width: 993px) {
        position: absolute;
        top: 300px;
        bottom: 0;
        background-color: transparent;
      }
      @include responsive(LG) {
        top: 162px;
        height: 72px;
      }
    }
  }
}

.calendar-spinner {
  position: absolute;
  z-index: 100;
  opacity: 0.5;
  display: flex;
  align-items: center;

  & .button-loading-spinner {
    margin: 0 auto;
  }

  @include responsive(XXS) {
    bottom: 20px;
    height: 880px;
    width: 100%;
  }
  @include responsive(LG) {
    bottom: -80px;
    height: 400px;
    width: 610px;
  }
}